// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from 'reactstrap';
import classnames from 'classnames';

//Import components
import SectionTitle from '../../components/Shared/SectionTitle';
import ReviewsSlider from '../../components/Shared/ReviewsSlider';

//Import Images
import img1 from '../../assets/images/client/01.jpg';
import img2 from '../../assets/images/client/02.jpg';
import img3 from '../../assets/images/client/03.jpg';
import img4 from '../../assets/images/client/04.jpg';
import img5 from '../../assets/images/client/05.jpg';
import img6 from '../../assets/images/client/06.jpg';

class PricesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: true,
      pathItems: [
        //id must required
        { id: 1, name: 'Landrick', link: '/' },
        { id: 2, name: 'Page', link: '#' },
        { id: 3, name: 'Pricing' },
      ],
      pricings: [
        {
          id: 1,
          title: 'Free',
          price: 0,
          duration: 'mo',
          buttonText: 'Buy Now',
          btnLink: '',
          features: [
            { title: 'Full Access' },
            { title: 'Enhanced Security' },
            { title: 'Source Files' },
            { title: '1 Domain Free' },
          ],
        },
        {
          id: 2,
          title: 'Starter',
          price: 39,
          duration: 'mo',
          buttonText: 'Get Started',
          btnLink: '',
          isActive: true,
          features: [
            { title: 'Full Access' },
            { title: 'Source Files' },
            { title: 'Free Appointments' },
            { title: 'Free Installment' },
            { title: 'Enhanced Security' },
          ],
        },
        {
          id: 3,
          title: 'PROFESSIONAL',
          price: 59,
          duration: 'mo',
          buttonText: 'Try It Now',
          btnLink: '',
          features: [
            { title: 'Full Access' },
            { title: 'Enhanced Security' },
            { title: 'Source Files' },
            { title: '1 Domain Free' },
          ],
        },
      ],
      pricings1: [
        {
          id: 1,
          title: 'Free',
          price: 0,
          duration: 'mo',
          buttonText: 'Buy Now',
          btnLink: '',
          features: [{ title: 'Full Access' }, { title: 'Source Files' }],
        },
        {
          id: 2,
          title: 'Starter',
          price: 39,
          duration: 'mo',
          buttonText: 'Get Started',
          btnLink: '',
          isActive: true,
          features: [
            { title: 'Full Access' },
            { title: 'Source Files' },
            { title: 'Free Appointments' },
          ],
        },
        {
          id: 3,
          title: 'PROFESSIONAL',
          price: 59,
          duration: 'mo',
          buttonText: 'Try It Now',
          btnLink: '',
          features: [
            { title: 'Full Access' },
            { title: 'Source Files' },
            { title: '1 Domain Free' },
            { title: 'Enhanced Security' },
          ],
        },
        {
          id: 4,
          title: 'ULTIMATE',
          price: 79,
          duration: 'mo',
          buttonText: 'Started Now',
          btnLink: '',
          features: [
            { title: 'Full Access' },
            { title: 'Enhanced Security' },
            { title: 'Source Files' },
            { title: '1 Domain Free' },
            { title: 'Free Installment' },
          ],
        },
      ],

      monthPricing: [
        {
          id: 1,
          price: 0,
          title: 'FREE',
          action: 'Buy Now',
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
          ],
        },
        {
          id: 2,
          price: 39,
          title: 'STARTER',
          action: 'Get Started',
          isBest: true,
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
            { id: 3, subItem: 'Free Appointments' },
          ],
        },
        {
          id: 3,
          price: 59,
          title: 'PROFESSIONAL',
          action: 'Try It Now',
          isBgClass: true,
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
            { id: 3, subItem: '1 Domain Free' },
            { id: 4, subItem: 'Enhanced Security' },
          ],
        },
        {
          id: 4,
          price: 79,
          title: 'ULTIMATE',
          action: 'Started Now',
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Enhanced Security' },
            { id: 3, subItem: 'Source Files' },
            { id: 4, subItem: '1 Domain Free' },
            { id: 5, subItem: 'Free Installment' },
          ],
        },
      ],

      yearPricing: [
        {
          id: 1,
          price: 10,
          title: 'FREE',
          action: 'Buy Now',
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
          ],
        },
        {
          id: 2,
          price: 139,
          title: 'STARTER',
          isBest: true,
          action: 'Get Started',
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
            { id: 3, subItem: 'Free Appointments' },
          ],
        },
        {
          id: 3,
          price: 159,
          title: 'PROFESSIONAL',
          action: 'Try It Now',
          isBgClass: true,
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Source Files' },
            { id: 3, subItem: '1 Domain Free' },
            { id: 4, subItem: 'Enhanced Security' },
          ],
        },
        {
          id: 4,
          price: 179,
          title: 'ULTIMATE',
          action: 'Started Now',
          isChildItem: [
            { id: 1, subItem: 'Full Access' },
            { id: 2, subItem: 'Enhanced Security' },
            { id: 3, subItem: 'Source Files' },
            { id: 4, subItem: '1 Domain Free' },
            { id: 5, subItem: 'Free Installment' },
          ],
        },
      ],
      reviews: [
        {
          id: 1,
          img: img1,
          name: 'Thomas Israel',
          post: 'C.E.O',
          desc: 'It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.',
          rating: 5,
        },
        {
          id: 2,
          img: img2,
          name: 'Barbara McIntosh',
          post: 'M.D',
          desc: 'One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.',
          rating: 4.5,
        },
        {
          id: 3,
          img: img3,
          name: 'Carl Oliver',
          post: 'P.A',
          desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          rating: 5,
        },
        {
          id: 4,
          img: img4,
          name: 'Christa Smith',
          post: 'Manager',
          desc: 'According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.',
          rating: 5,
        },
        {
          id: 5,
          img: img5,
          name: 'Dean Tolle',
          post: 'Developer',
          desc: 'There is now an abundance of readable dummy texts. These are usually used when a text is required.',
          rating: 5,
        },
        {
          id: 6,
          img: img6,
          name: 'ill Webb',
          post: 'Designer',
          desc: 'Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.',
          rating: 5,
        },
      ],
      activeTab: '1',
    };
    this.toggleTab = this.toggleTab.bind(this);
    // this.toggleTab1 = this.toggleTab1.bind(this);
    // this.toggle = this.setState(!this.state.plan);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle = () => {
    this.setState({ plan: false });
  };

  // toggleTab1(tab1) {
  //   if (this.state.activeTab1 !== tab1) {
  //     this.setState({
  //       activeTab1: tab1,
  //     });
  //   }
  // }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  toggleSwitcher = (checked) => {
    const filtMonthly = document.getElementById('filt-monthly');
    const filtYearly = document.getElementById('filt-yearly');
    const monthly = document.getElementById('monthly');
    const yearly = document.getElementById('yearly');

    if (checked) {
      filtYearly.classList.add('toggler--is-active');
      filtMonthly.classList.remove('toggler--is-active');
      yearly.classList.remove('hide');
      monthly.classList.add('hide');
    } else {
      filtMonthly.classList.add('toggler--is-active');
      filtYearly.classList.remove('toggler--is-active');
      monthly.classList.remove('hide');
      yearly.classList.add('hide');
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> e-Arşiv Paketleri </h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/">Anasayfa</Link>
                  </li>
                  <li className="breadcrumb-item active text-danger" aria-current="page">
                    Paketler
                  </li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>{' '}
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container className="mt-50 mt-60">
            {/* Render Section Title */}
            <SectionTitle desc="Kullanım ihtiyacınıza göre 6 aylık veya yıllık planlar oluşturabilir, esnek fiyat seçenekleriyle satın alabilirsiniz." />

            <Row className="align-items-center">
              <Col className="col-12 mt-4 pt-2">
                <div className="text-center">
                  <Nav
                    pills
                    className="nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
                  >
                    <NavItem className="d-inline-block">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === '1' },
                          { 'bg-danger': this.state.activeTab === '1' },
                          'px-3 rounded-pill monthly',
                        )}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        6 Aylık
                      </NavLink>
                    </NavItem>{' '}
                    <NavItem className="d-inline-block">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === '2' },
                          { 'bg-danger': this.state.activeTab === '2' },
                          'px-3 rounded-pill yearly',
                        )}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        Yıllık
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow bg-light border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              Türkiye (6 aylık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">₺</span>
                              <span className="price h1 mb-0">750</span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              Avrupa (6 aylık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">€</span>
                              <span className="price h1 mb-0">110</span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow bg-light border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              ABD (6 aylık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">$</span>
                              <span className="price h1 mb-0">150</span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow bg-light border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              Türkiye (Yıllık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">₺</span>
                              <span className="price h1 mb-0">1500</span>
                              <span className="h4 align-self-end mb-1">
                                /yıl
                              </span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              Avrupa (Yıllık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">€</span>
                              <span className="price h1 mb-0">200</span>
                              <span className="h4 align-self-end mb-1">
                                /yıl
                              </span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="col-12 mt-4 pt-2">
                        <Card className="pricing pricing-danger business-rate shadow bg-light border-0 rounded">
                          <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">
                              ABD (Yıllık)
                            </h6>
                            <div className="d-flex mb-4">
                              <span className="h4 mb-0 mt-2">$</span>
                              <span className="price h1 mb-0">275</span>
                              <span className="h4 align-self-end mb-1">
                                /yıl
                              </span>
                            </div>

                            <ul className="list-unstyled mb-0 ps-0">
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Bütün arşiv
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Tam metin arama
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Sırala ve Filtrele
                              </li>
                              <li className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                Paylaş
                              </li>
                            </ul>
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              className="btn btn-danger mt-4"
                            >
                              Satın al
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PricesPage;
