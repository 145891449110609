import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//Import components
import AccordianCommon from '../../components/Shared/AccordianCommon';

import ScrollspyNav from './ScrollspyNav';

class HelpCenterFaqs extends Component {
  componentDidMount() {
    document.body.classList = '';
    window.addEventListener('scroll', this.scrollNavigation, true);
    // document.querySelector(".cookie-popup").classList.remove("show");
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  render() {
    const ids = ['tech', 'general', 'payment', 'support'];
    return (
      <React.Fragment>
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0">Sık Sorulan Sorular</h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/">Anasayfa</Link>
                  </li>
                  <li className="breadcrumb-item active text-danger" aria-current="page">
                    Yardım
                  </li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} md={5} xs={12} className="d-none d-md-block">
                <div className="rounded shadow p-4 sticky-bar">
                  <ScrollspyNav
                    scrollTargetIds={ids}
                    activeNavclassName="active"
                    scrollDuration="800"
                    headerBackground="true"
                    className="sticky-bar"
                  >
                    <ul className="list-unstyled sidebar-nav mb-0 py-0">
                      <li className="navbar-item p-0">
                        <a href="#tech" className="h6 text-dark navbar-link">
                          Genel Sorular
                        </a>
                      </li>
                      <li className="navbar-item mt-3 p-0">
                        <a href="#general" className="h6 text-dark navbar-link">
                          Arama ve Filtreleme
                        </a>
                      </li>
                      <li className="navbar-item mt-3 p-0">
                        <a href="#payment" className="h6 text-dark navbar-link">
                          Hesap Açma
                        </a>
                      </li>
                      <li className="navbar-item mt-3 p-0">
                        <a href="#support" className="h6 text-dark navbar-link">
                          Ödeme ve Abonelikler
                        </a>
                      </li>
                    </ul>
                  </ScrollspyNav>
                </div>
              </Col>

              <Col lg={8} md={7} xs={12}>
                <div className="section-title" id="tech">
                  <h4>Genel Sorular</h4>
                </div>
                <div className="faq-content mt-4 pt-2">
                  <div className="accordion" id="accordionExampleone">
                    {/* accoridan */}
                    <AccordianCommon
                      question1="Agos Arşivi nedir?"
                      answer1="Agos Arşivi, 1996’dan beri çıkmakta olan gazetemize dijital erişimin sağlandığı ve tüm sayılar içerisinde tam metin arama yapılabilen yapay zeka temelli bir e-arşiv uygulamasıdır."
                      question2="Agos Arşivinde nasıl arama yapılır?"
                      answer2="egazete.agos.com.tr ana sayfasındaki arama kutusuna, aradığınız kelime ya da kelimeleri yazarak hızlı arama yapabilirsiniz. Sistem size en doğru sonuçları ve gazeteleri sunar.  Ayrıca bu alana aradığınız bir gazetenin gün ay ve yılını yazarak da gazeteye ulaşabilirsiniz."
                      question3="Agos Gazetesi'nin fiziki arşivini (kağıt) buradan temin edebilir miyim?"
                      answer3="e-Arşiv uygulaması yalnızca dijital hizmet sağlar. Gazeteleri bilgisayar ortamında okuyabilir, istediğiniz sayfadan ekran resmi alabilirsiniz. Gerçek (kağıt baskı) kopyalar sadece yakın tarihli yayınlarda talep edilebilir. Eski arşiv gazetelerinin satışı bulunmamaktadır."
                      question4="Hesap oluşturmadan arşive erişebilir miyim?"
                      answer4={
                        <>
                          Maalesef! Arşiv içerisinde arama yapabilmek ve gazete
                          sayfalarını görüntüleyebilmek için{' '}
                          <Link
                            to="https://www.agos.com.tr/tr/e-gazete"
                            target="_blank"
                          >
                            agos.com.tr/tr/e-gazete
                          </Link>{' '}
                          linkinden üyelik oluşturmanız ve size uygun paketi
                          satın almanız gerekmektedir.
                        </>
                      }
                    />
                  </div>
                </div>

                <div className="section-title mt-5" id="general">
                  <h4>Arama ve Filtreleme</h4>
                </div>

                <div className="faq-content mt-4 pt-2">
                  <div className="accordion" id="accordionExampleTwo">
                    {/* accoridan */}
                    <AccordianCommon
                      question1="Giriş Nasıl Yapılır?"
                      answer1={
                        <>
                          <p>
                            <Link
                              to="https://www.agos.com.tr/account"
                              target="_blank"
                            >
                              Agos hesap sayfasına
                            </Link>{' '}
                            giderek giriş yapın veya yeni bir üyelik oluşturun.
                          </p>
                          <p>
                            Üyeliğinizle giriş yaptıktan sonra,{' '}
                            <Link
                              to="https://www.agos.com.tr/tr/pages/abonelik"
                              target="_blank"
                            >
                              E-GAZETE butonuna tıklayarak
                            </Link>{' '}
                            istediğiniz paketi satın alın.
                          </p>
                          <p>
                            Satın alma işleminizin ardından,
                            <Link to="/" target="_blank">
                              {' '}
                              https://egazete.agos.com.tr{' '}
                            </Link>{' '}
                            adresine giderek Agos Gazete arşivine erişin.
                          </p>
                        </>
                      }
                      question2="Arama ve Filtreleme Nasıl Yapılır?"
                      answer2={
                        <>
                          <p>
                            Arama çubuğuna anahtar kelime veya ifade yazarak
                            arama yapın.
                          </p>
                          <p>
                            Sonuçları üst kısmındaki tarih aralığı ile
                            filtreleyebilirsiniz. Ayrıca, "sonuçları sırala"
                            bölümünden eser, yazar ve yayın tarihine göre
                            filtreleme yapabilirsiniz.
                          </p>
                          <p>
                            İlgilendiğiniz sonuca "hemen oku" butonuna
                            tıklayarak erişim sağlayabilirsiniz.
                          </p>
                        </>
                      }
                      question3="Arama İpuçları Hakkında Bilgi"
                      answer3={
                        <>
                          <p>
                            Gazete arşivinde Latin ya da Ermeni alfabesiyle
                            arama yapabilirsiniz.
                          </p>
                          <p>
                            Tam eşleşen kelime ya da kelime gruplarını bulmak
                            için tırnak işaretlerini kullanabilirsiniz (Örneğin,
                            "ürperti" veya "Ziya Paşa").
                          </p>
                          <p>
                            Aramanızı zenginleştirmek için aradığınız kelimenin
                            yalın/eksiz hallerini yazarak tüm sonuçları
                            görüntüleyebilirsiniz. (Örneğin; "olduğundan,"
                            "olanlar," "olduğu" vs. için sadece "oldu"
                            yazabilirsiniz.)
                          </p>
                        </>
                      }
                      question4="Viewer (Görüntüleyici) Nasıl Kullanılır?"
                      answer4={
                        <>
                          <b>Sol üst köşedeki butonlar sırasıyla şunlardır:</b>
                          <ul>
                            <li>
                              Dosyada Ara: Doküman içinde farklı kelimeleri veya
                              ifadeleri arayabilirsiniz.
                            </li>
                            <li>
                              İndirilebilir Dosyalar: Sayfayı indirmek için sol
                              kısımdaki ilgili ikonu tıklayabilirsiniz.
                            </li>
                            <li>
                              Paylaş: İlgili gazetenin linkini Facebook,
                              Twitter, WhatsApp, Instagram ve e-posta gibi
                              iletişim araçları ile doğrudan paylaşabilirsiniz.
                            </li>
                            <li>
                              Ayarlar: Görüntü üzerinde netlik, tonlama gibi
                              filtreler yapabilirsiniz.
                            </li>
                          </ul>
                        </>
                      }
                    />
                  </div>
                </div>

                <div className="section-title mt-5" id="payment">
                  <h4>Hesap Açma</h4>
                </div>

                <div className="faq-content mt-4 pt-2">
                  <div className="accordion" id="accordionExampleThree">
                    {/* accoridan */}
                    <AccordianCommon
                      question1="Agos Arşiv Hesabı Nasıl Oluşturabilirim?"
                      answer1={
                        <>
                          <b>
                            Agos hesabınızı oluşturmak için aşağıdaki adımları
                            takip edebilirsiniz:
                          </b>
                          <ul>
                            <li>
                              <Link
                                to="https://www.agos.com.tr/account"
                                target="_blank"
                              >
                                https://www.agos.com.tr/account
                              </Link>{' '}
                              adresine gidin.
                            </li>
                            <li>
                              "Kayıt Ol" alanında yer alan gerekli bilgileri
                              doldurun.
                            </li>
                            <li>
                              E-posta onayını tamamlayarak hesabınızı
                              etkinleştirin.
                            </li>
                          </ul>
                        </>
                      }
                      question2="Hesap Oluştururken Hangi Bilgileri Girmem Gerekiyor?"
                      answer2={
                        <>
                          <b>
                            Hesap oluştururken aşağıdaki bilgileri sağlamanız
                            gerekecektir:
                          </b>
                          <ul>
                            <li>Kullanıcı adı</li>
                            <li>E-posta adresi</li>
                            <li>Şifre</li>
                          </ul>
                        </>
                      }
                      question3="Şifremi Unuttum, Ne Yapmalıyım?"
                      answer3={
                        <>
                          Eğer şifrenizi unuttuysanız,{' '}
                          <Link
                            to="https://www.agos.com.tr/tr/404?aspxerrorpath=/account/reset-password"
                            target="_blank"
                          >
                            şifre sıfırlama sayfasına
                          </Link>{' '}
                          giderek ilgili e-posta adresinizi kullanarak şifrenizi
                          sıfırlayabilirsiniz.
                        </>
                      }
                      question4="Hesap Bilgilerimi Nasıl Güncellerim?"
                      answer4={
                        <>
                          Hesap bilgilerinizi güncellemek için hesap sayfanıza
                          gidin. Burada profil bilgilerinizi düzenleyebilir ve
                          güncel bilgilerinizi kaydedebilirsiniz.
                        </>
                      }
                      question5="Hesap Oluşturmak Ücretsiz mi?"
                      answer5="Evet, Agos hesabı oluşturmak tamamen ücretsizdir. Ancak, abonelik hizmetlerinden yararlanmak için ilgili abonelik ücretlerini ödemeniz gerekebilir."
                      question6="Hesap Güvenliği İçin Hangi Önlemleri Almalıyım?"
                      answer6={
                        <>
                          <b>
                            Hesap güvenliğiniz için aşağıdaki önlemleri almanız
                            önemlidir:
                          </b>
                          <ul>
                            <li>Güçlü bir şifre kullanın.</li>
                            <li>Hesap bilgilerinizi güncel tutun.</li>
                            <li>
                              Hesap erişim güvenliği için iki faktörlü kimlik
                              doğrulamayı etkinleştirin.
                            </li>
                          </ul>
                        </>
                      }
                      question7="Hesabımı Nasıl Kapatırım?"
                      answer7={
                        <>
                          Hesabınızı kapatmak için{' '}
                          <Link
                            to="https://www.agos.com.tr/tr/pages/iletisim"
                            target="_blank"
                          >
                            müşteri hizmetleri
                          </Link>{' '}
                          ile iletişime geçebilirsiniz. Yetkililer size hesap
                          kapatma süreci konusunda yardımcı olacaklardır.
                        </>
                      }
                    />
                  </div>
                </div>
                <div className="section-title mt-5" id="support">
                  <h4>Ödeme ve Abonelikler</h4>
                </div>

                <div className="faq-content mt-4 pt-3">
                  <div className="accordion" id="accordionExamplefour">
                    <AccordianCommon
                      question1="Abonelik Ücretini Nasıl Ödeyebilirim?"
                      answer1={
                        <>
                          <b>
                            Abonelik ücretinizi ödemek için aşağıdaki adımları
                            takip edebilirsiniz:
                          </b>
                          <ul>
                            <li>
                              <Link
                                to="https://www.agos.com.tr/tr/pages/abonelik"
                                target="_blank"
                              >
                                https://www.agos.com.tr/tr/pages/abonelik
                              </Link>
                              adresine gidin.
                            </li>
                            <li>
                              İlgili abonelik paketinizi seçin ve ödeme
                              adımlarını takip ederek güvenli bir şekilde
                              ödemenizi gerçekleştirin.
                            </li>
                          </ul>
                        </>
                      }
                      question2="Abonelik Ücreti Ne Kadar ve Hangi Hizmetleri İçerir?"
                      answer2={
                        <>
                          Abonelik ücretleri ve içerdikleri hizmetler,
                          <Link
                            to="https://www.agos.com.tr/tr/abonelik/satin-al"
                            target="_blank"
                          >
                            https://www.agos.com.tr/tr/abonelik/satin-al
                          </Link>
                          adresindeki abonelik paketleri bölümünde detaylı bir
                          şekilde listelenmektedir. İlgilendiğiniz paketi
                          seçerek içeriğini inceleyebilirsiniz.
                        </>
                      }
                      question3="Ödeme Yöntemleriniz Nelerdir?"
                      answer3={
                        <>
                          <b>
                            Ödeme yapmak için kabul ettiğimiz yöntemler
                            şunlardır:
                          </b>
                          <ul>
                            <li>Kredi Kartı</li>
                            <li>Banka Havale</li>
                          </ul>
                        </>
                      }
                      question4="Aboneliğimle İlgili Sorun Yaşıyorsam Ne Yapmalıyım?"
                      answer4={
                        <>
                          Eğer aboneliğinizle ilgili herhangi bir sorunla
                          karşılaşırsanız,{' '}
                          <Link
                            to="https://www.agos.com.tr/tr/pages/iletisim"
                            target="_blank"
                          >
                            müşteri hizmetleri
                          </Link>{' '}
                          ile iletişime geçerek destek alabilirsiniz. Yetkililer
                          size yardımcı olmak için hazır olacaktır.
                        </>
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default HelpCenterFaqs;
