import React, { useState } from 'react';
import { Link, useNavigate, createSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from 'reactstrap';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import bg1 from '../../assets/images/it/bg1.jpg';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import useModalStore from '../../stores/modal';

const Section = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = useModalStore(state => state.toggleModal);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      query: '',
    },
    validationSchema: Yup.object({
      query: Yup.string().required('Metin alanı boş olamaz'),
    }),
    onSubmit: (values) => {
      navigate({
        pathname: '/result',
        search: createSearchParams({
          query: encodeURIComponent(values.query),
        }).toString(),
      });
    },
  });

  return (
    <React.Fragment>
      <section
        className="bg-half-170 d-table w-100 it-home"
        style={{
          background: `url(${bg1}) center center`,
        }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6} xs={12}>
              <div className="title-heading">
                <h1 className="fw-bold text-white title-dark mt-2 mb-3">
                  Agos e-gazete hizmetinizde <br /> Bugünü ve geçmişi anlamak için...
                </h1>
                <p className="para-desc text-white-50">
                  Hemen aramaya başlayın! Agos’un son çeyrek yüzyıla tanıklık
                  eden arşivinde yolculuğa çıkın. Önemli haberler, başlıca
                  olaylar, kültürel dönüşümler ve daha fazlası.
                </p>
                {/* <div className="mt-4 pt-2">
                  <Link
                    to="#"
                    onClick={() => setOpen(true)}
                    className="btn btn-icon btn-pills btn-primary m-1 lightbox"
                  >
                    <FeatherIcon icon="video" className="icons" />
                  </Link>
                  <small className="fw-bold text-uppercase text-white-50 align-middle ms-1">
                    NASIL KULLANILIR (İZLE)
                  </small>
                </div> */}
              </div>
            </Col>

            <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Card className="shadow rounded border-0 ms-lg-5">
                <CardBody>
                  <h5 className="card-title fw-bold">
                    27 Yıllık Agos Arşivi bir tık uzağınızda
                  </h5>
                  <h6>Tüm sayılar içerisinde arama yapın</h6>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form"
                  >
                    <Row style={{ marginTop: '20px' }}>
                      <Col md={12}>
                        <div className="mb-3">
                          <div className="form-icon position-relative">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Metin giriniz"
                              name="query"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.query || ''}
                              invalid={
                                validation.touched.query &&
                                validation.errors.query
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.query &&
                            validation.errors.query ? (
                              <FormFeedback type="invalid">
                                {validation.errors.query}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-check mb-3  ps-0">
                          <Label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Arama ipuçlarını görmek için{' '}
                            <Link to="#" onClick={() => toggleModal('searchhint', true)} className="text-danger">
                              tıklayınız
                            </Link>
                          </Label>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="d-grid">
                          <button className="btn btn-danger">
                            Sonuçları Bul
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalVideo
            channel="youtube"
            isOpen={open}
            videoId="yba7hPeTSjk"
            onClose={() => setOpen(false)}
          />
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Section;
