import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  DayPicker,
  useDayPicker,
  useDayRender,
  useNavigation
} from "react-day-picker";
import {
  format,
  getYear,
  isSameMonth,
  setMonth,
  setYear
} from "date-fns";
import { tr } from "date-fns/locale";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Label
} from "reactstrap";

// UI
import styles from './DatePicker.module.css';

export default function DatePicker({
  name = 'date',
  label,
  value,
  defaultValue = 'Seçiniz.',
  onChange,
  onSubmit,
  ...props
}) {
  const [selected, setSelected] = useState(value);
  const [month, setMonth] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const date = selected
    ? format(selected, 'yyyy-MM-dd')
    : '';
  const handleSelect = (value) => {
    if (! value) return;

    setSelected(value);

    if (onChange) {
      onChange(value)
    }
  }

  const handleClear = () => {
    setSelected(null);
    onSubmit(null);
  };

  const handleSubmit = () => {
    setIsOpen(false);

    // callback
    if (onSubmit && selected) {
      onSubmit(selected);
    }
  }

  const handleToggle = () => {
    const newValue = !isOpen;

    // dropdown kapandığında submit butonuna 
    // basılmadığında tarih seçimini iptal edelim.
    if (! newValue && selected) setSelected(value); 

    setIsOpen(newValue);
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={handleToggle}
      color="primary" 
      direction="up">
      <DropdownToggle color="light" className="bg-transparent border-0 cursur-pointer mb-4 p-0">
        <div className="input-group">
          <span className="input-group-text bg-transparent border-light-subtle">
            <i className="uil uil-calender" />
          </span>
          <div className="form-floating">
            <input 
              type="text" 
              className="form-control" 
              value={date || defaultValue}
              id={name} 
              readOnly
              placeholder="" 
            />
            <Label htmlFor={name} className="fw-medium">{label}</Label>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu
        end={true}
        className="dropdown-menu-end bg-white border-light-subtle shadow rounded my-2 p-0">
        <input 
          type="hidden"
          name={name}
          value={date}
        />
        <DayPicker
          mode="single"
          weekStartsOn={1}
          numberOfMonths={1}
          locale={tr}
          showOutsideDays={true}
          month={month || undefined}
          onMonthChange={setMonth}
          selected={selected || undefined}
          onSelect={handleSelect}
          toDate={new Date()}
          disableNavigation={false}
          components={{
            IconLeft: () => (
              <span className="bi bi-chevron-compact-left" />
            ),
            IconRight: () => (
              <span className="bi bi-chevron-compact-right" />
            ),
            Day,
            Caption
          }}
          classNames={{
            months: "d-flex",
            month: "p-3",
            nav: "gap-1 d-flex align-items-center rounded-full size-full justify-content-between p-4",
            table: "w-100 border-collapse space-y-1 text-center",
            head_row: styles.head_row,
            head_cell: "fw-medium pb-2",
            row: "f-100 mt-1",
            cell: styles.cell,
            day: styles.day,
            day_today: styles.day_today,
            day_selected: "rounded bg-dark text-white",
            day_disabled: "opacity-25 text-decoration-line-through",
            day_outside: styles.day_outside,
            day_hidden: "hidden",
          }}
          {...props}
        />
      <div className="border-top d-flex gap-2 p-3">
        <Button 
          type="button"
          color="secondary" 
          className={styles.button} 
          disabled={! date}
          onClick={handleClear}
          outline>Temizle</Button>
        <Button 
          type="button"
          color="primary" 
          className={styles.button}
          disabled={! date}
          onClick={handleSubmit}>Uygula</Button>
      </div>
    </DropdownMenu>
  </Dropdown>
  )
}

function Caption({ displayMonth }) {
  const { goToMonth, nextMonth, previousMonth, displayMonths } =
    useNavigation()
  const { numberOfMonths } = useDayPicker()

  const displayIndex = displayMonths.findIndex((month) =>
    isSameMonth(displayMonth, month),
  )
  const isFirst = displayIndex === 0
  const isLast = displayIndex === displayMonths.length - 1

  const hideNextButton = numberOfMonths > 1 && (isFirst || !isLast)
  const hidePreviousButton =
    numberOfMonths > 1 && (isLast || !isFirst)

  const currentSelectedYear = getYear(displayMonth)
  const currentYear = getYear(new Date())
  const years = Array.from(
    { length: 101 },
    (_, i) => currentYear - 100 + i,
  )

  return (
    <div className="d-flex align-items-center justify-content-between gap-4 mb-2">
      <div className="d-flex align-items-center gap-1">
        <>
          <label htmlFor="year-select" className="sr-only" />
          <select 
            className="form-select w-auto"
            value={currentSelectedYear.toString()}
            onChange={(e) => goToMonth(
              setYear(displayMonth, parseInt(e.target.value)),
            )}>
            {years.map((year) => (
              <option key={year} value={year.toString()}>
                {year}
              </option>
            ))}
          </select>
          <label htmlFor="month-select" className="sr-only" />
          <select 
            className="form-select w-auto"
            value={displayMonth.getMonth().toString()}
            onChange={(e) =>
              goToMonth(
                setMonth(displayMonth, parseInt(e.target.value)),
              )
            }>
            {[
              "Ocak",
              "Şubat",
              "Mart",
              "Nisan",
              "Mayıs",
              "Haziran",
              "Temmuz",
              "Ağustos",
              "Eylül",
              "Ekim",
              "Kasım",
              "Aralık",
            ].map((month, index) => (
              <option key={month} value={index.toString()}>
                {month}
              </option>
            ))}
          </select>
        </>
      </div>
      <div className="d-flex align-items-center gap-1">
        {!hidePreviousButton && (
          <Button
            disabled={!previousMonth}
            aria-label="Go to previous month"
            size="sm"
            outline
            onClick={() => previousMonth && goToMonth(previousMonth)}>
            <i className="uil uil-angle-left-b" />
          </Button>
        )}
        {!hideNextButton && (
          <Button
            disabled={!nextMonth}
            aria-label="Go to next month"
            size="sm"
            outline
            onClick={() => nextMonth && goToMonth(nextMonth)}>
            <i className="uil uil-angle-right-b" />
          </Button>
        )}
      </div>
    </div>
  )
}

function Day({ date, displayMonth }) {
  const buttonRef = useRef(null);
  const {
    activeModifiers,
    buttonProps,
    divProps,
    isButton,
    isHidden,
  } = useDayRender(date, displayMonth, buttonRef)

  const { today } = activeModifiers

  if (isHidden) return <Fragment />

  if (!isButton) {
    return (
      <div
        {...divProps}
        className={[
          "d-flex align-items-center justify-content-center",
          divProps.className,
        ].join(' ')}
      />
    )
  }

  const {
    children: buttonChildren,
    className: buttonClassName,
    ...buttonPropsRest
  } = buttonProps

  return (
    <button
      ref={buttonRef}
      {...buttonPropsRest}
      type="button"
      className={["border-0 bg-transparent position-relative", buttonClassName].join(' ')}>
      {buttonChildren}
      {today && (
        <span
          className="position-absolute bottom-2 left-0 right-0 bg-primary" 
        />
      )}
    </button>
  )
}