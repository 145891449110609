import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

import useResultFilter from 'hooks/use-result-filter';
import Sort from 'components/Sort';
import Pagination from 'components/Pagination';
import CardDetail from './Card';
import ProductSkeleton from './Card/skeleton';

export default function ShopGrid() {
  const { data, isLoading } = useResultFilter(true);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={3} md={7}>
              <div className="section-title">
                <h5 className="mb-0 mb-md-4">Tüm Sayılar</h5>
              </div>
            </Col>

            <Sort />
          </Row>

          <Row>
            {isLoading ? (
              <ProductSkeleton />
            ) : data?.data?.length <= 0 ? (
              <div className="alert bg-warning-subtle border border-warning-subtle text-warning-emphasis text-center py-3">
                <h4 className="alert-heading">Sonuç bulunamadı.</h4>
                <p>Aramanıza uygun sonuç bulunamadı. Filtreleri temizleyerek tüm sayılara ulaşmak için aşağıdaki butona tıklayınız.</p>
                <hr />
                <Link
                  to="/publications" 
                  reloadDocument={true}
                  className="btn btn-secondary">Tüm Sayılar</Link>
              </div>
            ) : (
              data.data.map((product, key) => (
                <CardDetail key={key} {...product} />
              ))
            )}

            <Pagination data={data} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
