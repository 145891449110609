import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { sortService } from 'services';
import { Placeholder, Col, Row, Label } from 'reactstrap';
import { useListContext } from 'pages/result/context';
import useSearchQuery from 'hooks/use-search-query';
import { addDays, format } from 'date-fns';
import DatePicker from 'components/DatePicker';

export default function Sort() {
  const searchQuery = useSearchQuery();

  const { data, isLoading } = useQuery(
    ['sortList'],
    async () => sortService.getAll(),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  const { state } = useListContext();

  const startDate = useMemo(() => {
    const date = state.dates.start;
    if (! date) return null;
    return format(date, 'yyyy-MM-dd')
  }, [state.dates.start]);

  const endDate = useMemo(() => {
    const date = state.dates.end;
    if (! date) return null;
    return format(date, 'yyyy-MM-dd')
  }, [state.dates.end]);

  const updateDatePicker = (key, value) => {
    searchQuery.addPath(
      key,
      value
        ? format(value, 'dd.MM.yyyy')
        : ''
    );
  };

  return (
    <Col lg={9} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
      <Row className="justify-content-end">
        {isLoading || !data?.data ? (
          <Placeholder
            color="light"
            animation="wave"
            tag="div"
            xs={12}
            style={{
              width: '100%',
              height: '38px',
              borderRadius: '6px',
            }}
          />
        ) : (
          <Fragment>
            <Col xs={6} sm={3}>
              <DatePicker 
                name="start"
                label="Başlangıç Tarihi"
                value={startDate
                  ? new Date(startDate)
                  : null
                }
                onSubmit={(date) => updateDatePicker('start', date)}
              />
            </Col>
            <Col xs={6} sm={3}>
              <DatePicker 
                name="end"
                label="Bitiş Tarihi"
                value={endDate
                  ? new Date(endDate)
                  : null
                }
                fromDate={addDays(state.dates.start, 1)}
                onSubmit={(date) => updateDatePicker('end', date)}
              />
            </Col>
          </Fragment>
        )}
        <Col xs={12} sm={4} className="form custom-form">
          {isLoading || !data?.data ? (
            <Placeholder
              color="light"
              animation="wave"
              tag="div"
              xs={12}
              style={{
                width: '100%',
                height: '38px',
                borderRadius: '6px',
              }}
            />
          ) : (
            <div className="mb-0">
              <div className="input-group mb-4">
                <span className="input-group-text bg-transparent">
                  <i className="uil uil-sort-amount-up" />
                </span>
                <div className="form-floating">
                  <select 
                    value={state.sortBy}
                    className="form-select form-select-sm"
                    id="sortByList"
                    onChange={(e) => {
                      searchQuery.addPath('sort', e.target.value);
                    }}>
                    {Object.entries(data?.data.sort).map(([key, value], i) => (
                      <option value={key} key={i}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <Label htmlFor="sortByList" className="fw-medium">Sıralama</Label>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Col>
  );
}
