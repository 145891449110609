import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Cookies from 'universal-cookie';


import { BASE_TOKEN_NAME } from 'config';
import useModalStore from '../../stores/modal';
import { basePath } from '../../config';

//import images
import online1 from '../../assets/images/course/online/ab01.jpg';
import online2 from '../../assets/images/course/online/ab02.jpg';
import online3 from '../../assets/images/course/online/ab03.jpg';

const Aboutus = () => {
  const toggleModal = useModalStore(state => state.toggleModal);
  const cookies = new Cookies();

  const handleLink = (e) => {
    if (! cookies.get(BASE_TOKEN_NAME)) {
      e.preventDefault();
    
      toggleModal('premium', true);
    }
  }

  return (
    <React.Fragment>
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={6}>
            <Row className="align-items-center">
              <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <CardBody className="p-0">
                    <Link to={`${basePath}/agos-gazetesi-21-7-2023-372872/sayfa/1`} target="_blank" onClick={handleLink}>
                      <img src={online1} className="img-fluid" alt="" />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content">
                        <div className="title text-white d-block fw-bold">Agos Gazetesi</div>
                        <small className="text-light">21 Temmuz 2023</small>
                      </div>
                    </Link>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={6} xs={6}>
                <Row>
                  <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                      <CardBody className="p-0">
                        <Link to={`${basePath}/agos-gazetesi-7-7-2023-372871/sayfa/1`} target="_blank" onClick={handleLink}>
                          <img src={online2} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content">
                            <div className="title text-white d-block fw-bold">Agos Gazetesi</div>
                            <small className="text-light">14 Temmuz 2023</small>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12} md={12} className="mt-4 pt-2">
                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                      <CardBody className="p-0">
                        <Link to={`${basePath}/agos-gazetesi-18-01-1996-355781`} target="_blank" onClick={handleLink}>
                          <img src={online3} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content">
                            <div className="title text-white d-block fw-bold">Agos Gazetesi</div>
                            <small className="text-light">18 Ocak 1996</small>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
            <div className="ms-lg-4">
              <div className="section-title">
                <h4 className="title mb-4 mt-3">
                  Yakın tarihin yaşayan arşivi için: Agos’a göz atın
                </h4>
                <p className="text-muted para-desc">
                  1996’da Hrant Dink ve bir grup arkadaşının, Türkiyeli
                  Ermenilerin sorunlarını kamuoyuna anlatmak amacıyla kurdukları
                  Agos, Cumhuriyet döneminin Türkçe-Ermenice olarak yayımlanan
                  ilk gazetesidir.{' '}
                </p>
                <p className="text-muted para-desc mb-0">
                  Yakın tarihte meydana gelen olayları, değişimleri ve
                  meseleleri kayıt altına almış olan Agos, Wikilala ile birlikte
                  tüm arşivini bilim ve akademi camiasının erişimine açıyor.
                </p>
              </div>

              <div className="d-flex mt-4 pt-2">
                <i className="uil uil-bullseye h5 text-danger"></i>
                <div className="flex-1 ms-2">
                  <h5>Bütün koleksiyona erişin​</h5>
                  <p className="mb-0 text-muted">
                    1996’dan bu yana yayınlanan ve yayımı devam eden gazete
                    sayılarının tümüne erişin.
                  </p>
                </div>
              </div>

              <div className="d-flex mt-4 pt-2">
                <i className="uil uil-create-dashboard h5 text-danger"></i>
                <div className="flex-1 ms-2">
                  <h5>Tam metin arama yapın​</h5>
                  <p className="mb-0 text-muted">
                    Gelişmiş arama altyapısı ile Agos arşivinde bulmak
                    istediğiniz (yer, kişi, olay vs.) her şeyi tam metin
                    içerisinde arayın.
                  </p>
                </div>
              </div>

              <div className="mt-4 pt-2">
                <a
                  href="/publications"
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-danger m-1"
                >
                  Tüm yayınlara gözat
                  <i className="uil uil-angle-right-b align-middle"></i>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default Aboutus;
