// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//Import components
import SectionTitle from '../../components/Shared/SectionTitle';
import ReviewsSlider from '../../components/Shared/ReviewsSlider';
import { reviews } from '../../common/data';

const FeaturesPage = () => {
  const features = [
    {
      id: 1,
      icon: 'uil uil-books h2 text-danger',
      title: 'Tam koleksiyon',
      description:
        '1996’dan bu yana yayınlanan tam koleksiyon ile yakın tarihin sayfalarına göz atın.',
    },
    {
      id: 2,
      icon: 'uil uil-file-search-alt h2 text-danger',
      title: 'Tam metin arama',
      description:
        'Anahtar kelimelerle sayfaların içerisinde arama yapın, istediğiniz bilgiye anında ulaşın.',
    },
    {
      id: 3,
      icon: 'uil uil-sort-amount-up h2 text-danger',
      title: 'Sıralama',
      description:
        '"Arama sonuçlarını tarih veya sayfa sırasına göre sıralayarak aradığınızı bulun."',
    },
    {
      id: 4,
      icon: 'uil uil-filter h2 text-danger',
      title: 'Filtreleme (basım aralığına göre)',
      description:
        'Belgeleri belirli bir basım aralığına göre filtreleyerek istediğiniz döneme odaklanın.',
    },
    {
      id: 5,
      icon: 'uil uil-share h2 text-danger',
      title: 'Paylaş',
      description:
        'Dosyaları paylaşarak, içeriği kolayca erişilebilir hale getirin.',
    },
    {
      id: 6,
      icon: 'uil uil-adjust-half h2 text-danger',
      title: 'Görüntü optimizasyonu',
      description:
        'Görüntü optimizasyonu ile en iyi kalitede renk ayarı yapın ve daha net görüntüler elde edin.',
    },
  ];

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
    return () => window.removeEventListener('scroll', scrollNavigation);
  }, []);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };
  return (
    <React.Fragment>
      {/* breadcrumb */}
      <section className="bg-half-170 bg-light d-table w-100">
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h4 className="title mb-0">Özellikler</h4>
              </div>
            </Col>
          </Row>

          <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item">
                  <Link to="/">Anasayfa</Link>
                </li>{' '}
                <li className="breadcrumb-item active text-danger" aria-current="page">
                  Özellikler
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          {/* feature box */}
          <Row>
            {features.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-5">
                <div className="features feature-danger">
                  <div className="image position-relative d-inline-block">
                    <i className={item.icon}></i>
                  </div>

                  <div className="content mt-4">
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="section bg-light">
        <Container className="mt-60">
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">
                  e-Arşiv sayesinde geçmiş ve gelecek tüm sayılara her yerden
                  erişin.
                </h4>
                <p className="text-muted para-desc mx-auto">
                  Agos Gazetesi’nin tüm sayılarına her yerden rahatlıkla
                  ulaşabilirsiniz. e-Arşiv sayesinde, köşe yazılarından önemli
                  haberlere kadar geniş bir yelpazede içeriğe erişim sağlayarak
                  bilgiye hızlı ve kolay bir şekilde ulaşabilirsiniz.
                </p>
                <div className="mt-4">
                  <Link to="/prices" className="btn btn-danger mt-2 me-2">
                    Şimdi paket satın alın
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default FeaturesPage;
